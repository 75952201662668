import React, { useState, useEffect } from "react";
import partnerus from "../assets/images/partner-with-us.png";
import partner from "../assets/images/partner-with-us-mobile.png";
import { useNavigate } from "react-router-dom";

const PartnerUs = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    // <div className='m-4 ml-[10rem] p-4 h-[42rem] flex flex-col items-center'>
    //   <div className='text-5xl m-8'><span className='font-bold'>Partner</span> With Us</div>
    //   <div className='m-4 p-4 shadow-md '>
    //     <img src={partnerus} alt=''  />
    //   </div>
    // </div>
    <div
      className="container p-4 flex flex-col items-center mt-[60px] mb-[20px] md:mb-0 "
      itemScope
      itemType="https://schema.org/Organization"
    >
      <div className="text-5xl mb-12 ml-12 mr-12" itemProp="headline">
        <span className="font-bold">Partner</span> With Us
      </div>
      <div
        style={{ padding: isMobile ? "0px 20px" : "0px" }}
        onClick={() => {
          navigate("/partner-with-us");
        }}
        className=" flex justify-start"
        itemProp="potentialAction"
        itemScope
        itemType="https://schema.org/Action"
      >
        <div
          style={{ width: "100%" }}
          itemProp="image"
          itemScope
          itemType="https://schema.org/ImageObject"
        >
          <img
            src={isMobile ? partner : partnerus}
            alt="Collaborate With Us"
            description="Collaborate With Us"
            style={{ width: "100%", height: "auto", cursor: "pointer" }}
            itemProp="url"
          />
        </div>
      </div>
    </div>
  );
};

export default PartnerUs;
